<template>
  <div class="player-wrapper">
    <video
      ref="videoPlayer"
      class="video-js vjs-fill vjs-big-play-centered"
      controls
      @ended="$emit('video-ended')"
    ></video>
  </div>
</template>

<script>
import videojs from 'video.js';

require('@videojs/http-streaming/dist/videojs-http-streaming');

export default {
  name: 'MyPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
    onTimeUpdate: {
      type: Function,
      default: () => {},
    },
    onPause: {
      type: Function,
      default: () => {},
    },
    onPlay: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      player: null,
    };
  },
  // watch: {
  //   player() {
  //     this.renderPlayer();
  //     if (this.player.ended()) {
  //       this.$emit('ended');
  //     }
  //   },
  // },
  mounted() {
    this.renderPlayer();
    const theVideo = this.$refs.videoPlayer;
    if (theVideo) {
      document.onkeydown = function keyHandler(event) {
        const vidCurrentTime = theVideo.currentTime;

        switch (event.keyCode) {
          case 37:
            event.preventDefault();
            theVideo.currentTime = vidCurrentTime - 10;
            break;

          case 39:
            event.preventDefault();
            theVideo.currentTime = vidCurrentTime + 10;
            break;

          default:
            break;
        }
      };
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    renderPlayer() {
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        this.player.on('timeupdate', this.onTimeUpdate);
        this.player.on('play', this.onPlay);
        this.player.on('pause', this.onPause);
        const lastVideo = JSON.parse(localStorage.getItem('last_video'));
        if (lastVideo && this.$route.query.isLast) {
          this.player.on('loadedmetadata', () => {
            this.player.currentTime(lastVideo.moment);
          });
          // eslint-disable-next-line no-unused-vars
          let initDone = false;
          this.player.on('canplaythrough', () => {
            if (!initDone) {
              this.player.currentTime(lastVideo.moment);
              initDone = true;
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.player-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video-js {
  position: absolute !important;
  background-color: #ffffff !important;
}

.pk_video {
}
</style>
